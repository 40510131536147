import { action, computed, observable, makeObservable } from "mobx";

import { ApiClient, SequenceSearchResults } from "../api";

// Needs to be the same value as the genome-annotation-search-service k value
export const K = 31;

export default class SequenceSearchStore {
  api: ApiClient;

  constructor(api: ApiClient) {
    makeObservable(this);
    this.api = api;
  }

  @observable public sequence = "";
  @observable public loading = false;
  @observable public errored = false;
  @observable public results: SequenceSearchResults | null = null;
  // This is the Assembly uuid, not the genome one
  @observable public currentHighlighted: { uuid: string; colour: number } | null = null;

  @action.bound
  updateSequence(sequence: string) {
    this.sequence = sequence;
    this.results = null;
    this.currentHighlighted = null;
    this.loading = false;
    this.errored = false;
  }

  @action.bound
  changeHighlightedResult(highlight: { uuid: string; colour: number } | null) {
    this.currentHighlighted = highlight;
  }

  @action.bound
  async search() {
    if (this.sequence === "") {
      return;
    }

    this.loading = true;
    this.errored = false;
    this.results = null;
    this.currentHighlighted = null;

    const result = await this.api.post<SequenceSearchResults>(
      "/genome-portal-api/sequence-search",
      {
        sequence: this.sequence,
      }
    );
    this.loading = false;

    if (result.error === undefined) {
      this.errored = false;
      this.results = result.data;
    } else {
      this.markAsErrored();
    }
  }

  @action.bound
  clear() {
    this.loading = false;
    this.errored = false;
    this.sequence = "";
    this.results = null;
    this.currentHighlighted = null;
  }

  @action.bound
  markAsErrored() {
    //private markAsErrored() {
    this.loading = false;
    this.errored = true;
    this.results = null;
    this.currentHighlighted = null;
  }

  @computed
  get currentMatchingFlags(): boolean[] {
    if (this.currentHighlighted === null) {
      return this.allMatchingFlags;
    }

    const flags: boolean[] = Array.from<boolean>({ length: this.sequence.length }).fill(false);
    if (this.results === null) {
      return flags;
    }

    for (const item of this.results.matching_assemblies) {
      if (item.assembly.uuid == this.currentHighlighted.uuid) {
        for (const pos of item.bases_matching) {
          flags[pos] = true;
        }
        break;
      }
    }

    return flags;
  }

  @computed
  get allMatchingFlags(): boolean[] {
    const flags: boolean[] = Array.from<boolean>({ length: this.sequence.length }).fill(false);
    if (this.results === null) {
      return flags;
    }

    for (const item of this.results?.matching_assemblies) {
      for (const idx of item.bases_matching) {
        flags[idx] = true;
      }
    }
    return flags;
  }

  @computed
  get canSearch(): boolean {
    // Don't allow searches of exactly K, because we only show 80%+ matches
    return !this.loading && this.sequence.length >= K + 9;
  }
}
