import * as React from "react";
import { Button } from "@blueprintjs/core";
import { useContext } from "react";
import { StoresContext } from "../../pages/_app";
import { observer } from "mobx-react";

const CookieConsent = () => {
  const rootStore = useContext(StoresContext);
  if (!rootStore.userStore.showCookiePopup) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <span>
        This website uses cookies to ensure you get the best experience on our website.
        <a href="/cookies"> Learn more</a>
      </span>
      <span className="cookie-consent__actions">
        <span
          onClick={() => rootStore.userStore.setCookiesConsent(false)}
          className="cookie-consent__decline"
        >
          Decline
        </span>
        <Button id="cookie-accept" onClick={() => rootStore.userStore.setCookiesConsent(true)}>
          Allow cookies
        </Button>
      </span>
    </div>
  );
};

export default observer(CookieConsent);
