import * as React from "react";
import Loader from "../loader";

// The loader with some added text below
class Loading extends React.PureComponent<{ text: string }> {
  render() {
    return (
      <div className="loading">
        <Loader />
        <h3>{this.props.text}</h3>
      </div>
    );
  }
}

export default Loading;
