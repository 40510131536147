import Cookie from "js-cookie";
import getConfig from "next/config";
import { initializeAnalyticsTrackers, trackSegmentLogin } from "../utils/analytics";
import {
  CONSENT_COOKIE_NAME,
  DISMISSED_BANNERS_COOKIE_NAME,
  DUA_COOKIE_NAME,
} from "../utils/constants";
import { action, computed, observable, runInAction, makeObservable } from "mobx";
import { isServer } from "../pages/_app";
import { ApiClient, UserData } from "../api";

const { publicRuntimeConfig } = getConfig();

export type HydraData = {
  email: string;
  user_id: string;
  impersonating_user?: string;
};

function getFutureTimestamp(): Date {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  return now;
}

export default class UserStore {
  api: ApiClient;

  @observable hydraData: HydraData | null = null;

  @observable userData: UserData | null = null;

  // Callback to be called after user confirms data usage agreement.
  doActionCallback: (() => Promise<string | null>) | null = null;

  @observable cookieStatus: boolean | null = null;
  @observable dismissedBanners: string[] = [];

  @observable youNeedToLogInDialog = false;
  @observable youNeedToSubscribeDialog = false;
  @observable showLotIdOption = true;
  @observable youNeedToAgreeToDUADialog = false;

  constructor(api: ApiClient, ssrData?: UserStore) {
    makeObservable(this);

    this.api = api;
    if (ssrData) {
      runInAction(() => {
        this.hydraData = ssrData.hydraData;
        this.userData = ssrData.userData;
        this.cookieStatus = ssrData.cookieStatus;
        this.dismissedBanners = ssrData.dismissedBanners;
      });
    }
  }

  @action
  loadFromHeader(cookies: { [key: string]: string }) {
    if (cookies.data) {
      const decode = isServer
        ? (arg: string) => Buffer.from(arg, "base64").toString()
        : window.atob;
      this.hydraData = JSON.parse(decode(cookies.data));
    }

    if (cookies[CONSENT_COOKIE_NAME]) {
      if (cookies[CONSENT_COOKIE_NAME] === "true") {
        this.cookieStatus = true;
      }
      if (cookies[CONSENT_COOKIE_NAME] === "false") {
        this.cookieStatus = false;
      }
    }

    if (cookies[DISMISSED_BANNERS_COOKIE_NAME]) {
      this.dismissedBanners = cookies[DISMISSED_BANNERS_COOKIE_NAME].split(",");
    }
  }

  isLoggedIn(): boolean {
    return this.hydraData !== null;
  }

  isImpersonating(): boolean {
    if (this.hydraData === null) {
      return false;
    }
    return !!this.hydraData.impersonating_user;
  }

  isAcademic(): boolean {
    return this.userData?.is_academic || false;
  }

  hasActiveSubscription() {
    const endsAtWithLeeway = this.userData?.atcc_subscription?.ends_at_with_leeway;
    if (endsAtWithLeeway === undefined) {
      return false;
    }
    return new Date(endsAtWithLeeway) > new Date();
  }

  hasAgreedToDua(): boolean {
    const DUAValue = Cookie.get(DUA_COOKIE_NAME);
    return DUAValue === "true";
  }

  getActivePlan() {
    if (this.hasActiveSubscription()) {
      return this.userData?.atcc_subscription?.plan || null;
    }
    return null;
  }

  getUserEmail(): string | null {
    if (this.hydraData === null) {
      return null;
    }
    return this.hydraData.email;
  }

  getApiKey(): string | null {
    return this.userData?.api_key || null;
  }

  getAuthUrl(): string {
    // Cookie is signed so we need to extract the value
    const token = (Cookie.get("state") || "").split(".")[0].replace("s:", "");

    const oauthBaseUrl = publicRuntimeConfig.OAUTH_BASE_URL || "https://oauth.onecodex.com";
    return (
      `${oauthBaseUrl}/oauth2/auth?` +
      "client_id=" +
      publicRuntimeConfig.CLIENT_ID +
      "&response_type=code&" +
      "scope=offline&redirect_uri=" +
      publicRuntimeConfig.REDIRECT_URL +
      "&state=" +
      token
    );
  }

  // The username to show
  getUsername(): string {
    const text = this.getUserEmail() || "Log In";
    if (this.isImpersonating()) {
      return `${text} (Support mode)`;
    }
    return text;
  }

  getUserId(): string | null {
    return this.hydraData?.user_id || null;
  }

  getSegmentPayload() {
    if (this.hydraData === null) {
      return null;
    }
    return {
      email: this.getUserEmail(),
    };
  }

  @action
  loadCookiesConsent(): boolean | null {
    const val = Cookie.get(CONSENT_COOKIE_NAME);

    if (val === "true") {
      this.cookieStatus = true;
    }
    if (val === "false") {
      this.cookieStatus = false;
    }
    return this.cookieStatus;
  }

  @action
  setCookiesConsent(status: boolean) {
    this.cookieStatus = status;
    Cookie.set(CONSENT_COOKIE_NAME, status.toString(), {
      expires: getFutureTimestamp(),
      sameSite: "Lax",
    });

    if (status && this.canUseAnalytics) {
      initializeAnalyticsTrackers();
      trackSegmentLogin(this.getUserId(), this.getSegmentPayload());
    }
  }

  @action
  async reloadUserData() {
    if (!this.isLoggedIn()) {
      this.userData = null;
      return;
    }

    const result = await this.api.get<UserData>("/genome-portal-api/user-data");
    if (result.error === undefined) {
      runInAction(() => {
        this.userData = result.data;
      });
    }
  }

  @action
  tryToDoGuardedAction = (doAction: () => Promise<string | null>, showLotIdOption = true) => {
    if (!this.isLoggedIn()) {
      this.openYouNeedToLoginDialog();
      return;
    }

    if (!this.hasActiveSubscription()) {
      this.openYouNeedToSubscribeDialog(doAction, showLotIdOption);
      return;
    }

    if (!this.hasAgreedToDua()) {
      this.openYouNeedToAgreeToDUADialog(doAction);
      return;
    }
    doAction();
  };

  @action
  openYouNeedToLoginDialog() {
    this.youNeedToLogInDialog = true;
  }

  @action
  closeYouNeedToLoginDialog() {
    this.youNeedToLogInDialog = false;
  }

  @action
  openYouNeedToSubscribeDialog(doAction: () => Promise<string | null>, showLotIdOption: boolean) {
    this.doActionCallback = doAction;
    this.youNeedToSubscribeDialog = true;
    this.showLotIdOption = showLotIdOption;
  }

  @action
  closeYouNeedToSubscribeDialog() {
    this.youNeedToSubscribeDialog = false;
    this.doActionCallback = null;
  }

  @action
  openYouNeedToAgreeToDUADialog(doAction: () => Promise<string | null>) {
    this.doActionCallback = doAction;
    this.youNeedToAgreeToDUADialog = true;
  }

  @action
  closeYouNeedToAgreeToDUADialog() {
    this.doActionCallback = null;
    this.youNeedToAgreeToDUADialog = false;
  }

  @action
  agreeToDUA() {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + 1);
    Cookie.set(DUA_COOKIE_NAME, "true", { expires: expiry });
  }

  @computed
  get showCookiePopup(): boolean {
    return this.cookieStatus === null;
  }

  @computed
  get canUseAnalytics(): boolean {
    return this.cookieStatus === true && !this.isImpersonating();
  }

  @action
  dismissBanner(name: string) {
    if (!this.dismissedBanners.includes(name)) {
      this.dismissedBanners.push(name);
    }

    Cookie.set(DISMISSED_BANNERS_COOKIE_NAME, this.dismissedBanners.join(","), {
      expires: getFutureTimestamp(),
      sameSite: "Lax",
    });
  }
}
