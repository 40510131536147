import { isServer } from "../pages/_app";
import getConfig from "next/config";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { notifySentryCustomMessage } from "./errors";
import Cookie from "js-cookie";

const { publicRuntimeConfig } = getConfig();
export function logEvent(category = "", action = "", label = "") {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}

export function trackSegmentEvent(eventName: string, properties: any) {
  if (publicRuntimeConfig.SEGMENT_WRITE_KEY && !isServer && window.analytics) {
    window.analytics.track(eventName, properties);
  }
}

export function trackSegmentSignup(userId: string | null, traits: any) {
  if (typeof userId != "string") {
    return;
  }
  if (publicRuntimeConfig.SEGMENT_WRITE_KEY && !isServer && window.analytics) {
    window.analytics.identify(userId, traits);
    window.analytics.track("Sign Up", {});
  }
}

export function trackSegmentLogin(userId: string | null, traits: any) {
  if (typeof userId != "string") {
    return;
  }
  if (publicRuntimeConfig.SEGMENT_WRITE_KEY && !isServer && window.analytics) {
    window.analytics.identify(userId, traits);
    window.analytics.track("Log In", {});
  }
}

export function initializeAnalyticsTrackers() {
  const gaId = publicRuntimeConfig.GA_ID;
  if (gaId && !isServer) {
    ReactGA.initialize(gaId);
  }

  const gtmId = publicRuntimeConfig.GTM_ID;
  if (gtmId && !isServer) {
    TagManager.initialize({ gtmId: gtmId });
  }

  const segmentWriteKey = publicRuntimeConfig.SEGMENT_WRITE_KEY;
  // Segment removes the "load" function from the analytics object after initial load
  if (segmentWriteKey && !isServer && window.analytics && window.analytics.load) {
    window.analytics.load(segmentWriteKey);
  }
}

export function identifyEloquaUser(userPayloadString: string, redirectUrl: string) {
  const eloquaUrl = publicRuntimeConfig.ELOQUA_URL + "?" + userPayloadString;
  // Post user data to Eloqua to register their email against the Eloqua GUID
  // We do this each time a user signs up or logs in, differentially redirecting
  fetch(eloquaUrl, {
    method: "POST",
  })
    .then((response) => {
      console.log("eloqua response", response);
      if (response.status !== 200) {
        const err = new Error("Eloqua Integration Error");
        notifySentryCustomMessage(err, eloquaUrl, response.status);
      }
      window.location.href = redirectUrl;
    })
    .catch((error) => {
      console.log("Could not POST to Eloqua: ", error);
      window.location.href = redirectUrl;
    });
}

export function getEloquaCookieWithRetry(maxAttempts: number): Promise<string> {
  // the ELOQUA cookie is set by GTM after trackers are initialized
  // We need to wait for the 1st party cookie to be set before
  // we can use it to update identify the user within Eloqua
  return new Promise((resolve, reject) => {
    const cookieRetry = (n: number) => {
      const eloquaGuid = Cookie.get("ELOQUA");
      if (eloquaGuid) {
        return resolve(eloquaGuid);
      } else if (n === 1) {
        throw reject("Error fetching ELOQUA Code");
      } else {
        setTimeout(() => {
          cookieRetry(n - 1);
        }, 250);
      }
    };

    return cookieRetry(maxAttempts);
  });
}
