import getConfig from "next/config";

import { paths, components } from "./api-types";
import { isServer } from "./pages/_app";

export class ApiClient {
  public ssrCookie?: string = undefined;

  get<ResponseType>(
    url: keyof paths,
    pathParams: UrlParams = {},
    queryParams: UrlParams = {}
  ): Promise<RestCallResult<ResponseType>> {
    const fullUrl = makeFullUrl(url, pathParams || {}, queryParams || {});
    const headers = this.getAuthHeaders();
    return callFetch<ResponseType>(
      fetch(fullUrl, { credentials: "include", method: "GET", headers })
    );
  }

  post<ResponseType>(
    url: keyof paths,
    payload: unknown,
    pathParams: UrlParams = {},
    queryParams: UrlParams = {}
  ): Promise<RestCallResult<ResponseType>> {
    return this.withBodyRequest("POST", url, payload, pathParams || {}, queryParams || {});
  }

  patch<ResponseType>(
    url: keyof paths,
    payload: unknown,
    pathParams: UrlParams = {},
    queryParams: UrlParams = {}
  ): Promise<RestCallResult<ResponseType>> {
    return this.withBodyRequest("PATCH", url, payload, pathParams || {}, queryParams || {});
  }

  private getAuthHeaders(): { [key: string]: string } {
    if (isServer && this.ssrCookie) {
      return { Cookie: this.ssrCookie };
    }
    return {};
  }

  private withBodyRequest<ResponseType>(
    method: "POST" | "PATCH",
    url: keyof paths,
    payload: unknown,
    pathParams: UrlParams = {},
    queryParams: UrlParams = {}
  ): Promise<RestCallResult<ResponseType>> {
    const fullUrl = makeFullUrl(url, pathParams || {}, queryParams || {});
    const headers = { "Content-Type": "application/json", ...this.getAuthHeaders() };
    return callFetch<ResponseType>(
      fetch(fullUrl, {
        credentials: "include",
        method,
        body: JSON.stringify(payload),
        headers,
      })
    );
  }
}

export type Genome = components["schemas"]["Genome"];
export type BasicGenome = components["schemas"]["BasicGenome"];
export type GenomeInList = components["schemas"]["GenomeInList"];
export type TaxonWithParents = components["schemas"]["TaxonWithParents"];
export type AnnotationSet = components["schemas"]["AnnotationSet"];
export type Annotation = components["schemas"]["Annotation"];
export type AnnotationsResult = components["schemas"]["AnnotationsResult"];
export type AnnotationSequence = components["schemas"]["AnnotationSequence"];
export type AnnotationsCsvExportResult = components["schemas"]["AnnotationsCsvExportResult"];
export type GenomeStarred = components["schemas"]["GenomeStarred"];
export type DownloadLink = components["schemas"]["DownloadLink"];
export type RelatedAssembliesResult = components["schemas"]["RelatedAssembliesResult"];
export type RelatedAssemblyResult = components["schemas"]["RelatedAssemblyResult"];
export type AssemblyQCStatistics = components["schemas"]["AssemblyQCStatistics"];
export type ContigStatistics = components["schemas"]["ContigStatistics"];
export type ATCCGenomeMetadata = components["schemas"]["ATCCGenomeMetadata"];
export type CollectionName = components["schemas"]["Genome"]["collection_name"] | null;
export type GenomicVariant = components["schemas"]["GenomicVariant"];
export type PaginationMetadata = components["schemas"]["PaginationMetadata"];
export type SequenceSearchResults = components["schemas"]["SequenceSearchResults"];
export type AssemblyDerivedMetadata = components["schemas"]["AssemblyDerivedMetadata"];
export type GenomePublicationChange = components["schemas"]["GenomePublicationChange"];
export type GenomesOrderBy = Exclude<components["schemas"]["PaginatedGenomesQuery"]["order_by"], undefined>;
export type AtccQcStatistics = components["schemas"]["AtccQcStatistics"];
export type AnnotationsSummary = components["schemas"]["AtccAnnotationsSummary"];
export type StripeSessionResponse = components["schemas"]["StripeSessionResponse"]
export type UserData = components["schemas"]["UserData"]
export type CreateSubscriptionResponse = components["schemas"]["CreateSubscriptionResponse"]
export type DiscrepancyReportRun = components["schemas"]["DiscrepancyReportRun"]

const { publicRuntimeConfig } = getConfig();

type UrlParams = Record<string, string | number | boolean | null | string[]>;

type RestCallResult<ResponseType> =
  | {
      error: string;
      data: undefined;
      headers: undefined;
    }
  | {
      error: undefined;
      data: ResponseType;
      headers: Response["headers"];
    };

function makeFullUrl(url: keyof paths, pathParams: UrlParams, queryParams: UrlParams): string {
  let result: string = url;
  for (const key of Object.keys(pathParams)) {
    const val = encodeURIComponent(`${pathParams[key]}`);
    result = result.replace(`{${key}}`, val);
  }

  const query = new URLSearchParams();
  const queryKeys = Object.keys(queryParams);
  if (queryKeys.length > 0) {
    for (const key of queryKeys) {
      const val = queryParams[key];
      if (Array.isArray(val)) {
        for (const v of val) {
          query.append(key, `${v}`);
        }
      } else {
        query.set(key, `${val}`);
      }
    }
    result += "?" + query.toString();
  }

  return publicRuntimeConfig.BASE_URL + result;
}

async function callFetch<ResponseType>(
  fetchPromise: Promise<Response>
): Promise<RestCallResult<ResponseType>> {
  let error: string;
  try {
    const res = await fetchPromise;
    if (res.ok) {
      return {
        error: undefined,
        data: (await res.json()) as ResponseType,
        headers: res.headers,
      };
    } else {
      error = `${res.status}: ${res.statusText || "Unknown Error"}`;
    }
  } catch (err) {
    error = "Network Error";
  }
  return {
    error,
    data: undefined,
    headers: undefined,
  };
}
