import React, { useContext, useLayoutEffect, useState } from "react";
import { InputGroup, FormGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import Router from "next/router";
import { StoresContext } from "../../pages/_app";
import { setAfterLoginAction } from "../../pages/login-success";
import { Dialog, Classes, Button, Intent } from "@blueprintjs/core";
import { AppToaster } from "../toaster";

const DataDownloadDialogs = observer(() => {
  const rootStore = useContext(StoresContext);
  const { youNeedToLogInDialog, youNeedToSubscribeDialog, youNeedToAgreeToDUADialog } =
    rootStore.userStore;
  return (
    <>
      {youNeedToLogInDialog ? <LoginDialog /> : null}
      {youNeedToSubscribeDialog ? <SubscribeDialog /> : null}
      {youNeedToAgreeToDUADialog ? <UseAgreementDialog /> : null}
    </>
  );
});

const LoginDialog = () => {
  const rootStore = useContext(StoresContext);

  const handleClose = () => {
    rootStore.userStore.closeYouNeedToLoginDialog();
  };

  return (
    <Dialog
      icon="info-sign"
      isOpen={true}
      title="Log in to download data"
      usePortal={true}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          Downloads are only available to ATCC Genome Portal supporting members or those who have
          purchased a corresponding physical product.
        </p>
        <p>
          You can select a supporting membership plan or log in to enter the lot number associated
          with your ATCC product to continue.
        </p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            onClick={() => {
              handleClose();
              Router.push("/plans");
            }}
          >
            View Plans
          </Button>
          <Button
            onClick={() => {
              setAfterLoginAction("GoToViewPlans", Router.asPath);
              window.location.href = rootStore.userStore.getAuthUrl();
            }}
            intent={Intent.PRIMARY}
          >
            Log In
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const SubscribeDialog = observer(() => {
  const rootStore = useContext(StoresContext);
  const { showLotIdOption } = rootStore.userStore;
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    rootStore.genomeStore.setLotId("");
    return () => {
      rootStore.genomeStore.setLotId("");
    };
  }, []);

  const handleClose = () => {
    rootStore.userStore.closeYouNeedToSubscribeDialog();
  };

  const handleLotIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMsg("");
    rootStore.genomeStore.setLotId(event.target.value.trim());
  };

  const handlePrimaryActionClicked = () => {
    setErrorMsg("");
    const doActionCallback = rootStore.userStore.doActionCallback!;
    if (rootStore.genomeStore.lotId) {
      const doActionCallbackWrapper = async () => {
        setIsLoading(true);
        const err = await doActionCallback();
        setIsLoading(false);
        if (!err) {
          handleClose();
        } else {
          setErrorMsg(err);
        }
        return null;
      };

      if (rootStore.userStore.hasAgreedToDua()) {
        doActionCallbackWrapper();
      } else {
        rootStore.userStore.openYouNeedToAgreeToDUADialog(doActionCallbackWrapper);
      }
    } else {
      handleClose();
      Router.push("/plans");
    }
  };

  const lotIdSection = (
    <div className={Classes.DIALOG_BODY}>
      <p>
        Downloads are only available to ATCC Genome Portal supporting members or those who have
        purchased a corresponding physical product.
      </p>
      <p>
        You can select a supporting membership plan or enter the lot number associated with your
        ATCC product below to continue.
      </p>
      <FormGroup
        intent={errorMsg ? Intent.DANGER : Intent.NONE}
        subLabel={errorMsg ? errorMsg : undefined}
        label={<em>Lot Number</em>}
        labelFor="id_lot_number"
      >
        <InputGroup
          id="id_lot_number"
          intent={errorMsg ? Intent.DANGER : Intent.NONE}
          onChange={handleLotIdChange}
          value={rootStore.genomeStore.lotId}
          placeholder={"Please enter lot number"}
        />
      </FormGroup>
    </div>
  );

  const noLotIdOptionSection = (
    <div className={Classes.DIALOG_BODY}>
      <p>
        Downloading genome data requires an ATCC Genome Portal Supporting Membership. Please select
        a plan to continue.
      </p>
    </div>
  );

  return (
    <Dialog
      icon="info-sign"
      isOpen={true}
      title={
        showLotIdOption
          ? "Details required to download data"
          : "Become a supporting member to download data"
      }
      usePortal={true}
      onClose={handleClose}
    >
      {showLotIdOption ? lotIdSection : noLotIdOptionSection}
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePrimaryActionClicked} intent={Intent.PRIMARY} loading={isLoading}>
            {rootStore.genomeStore.lotId ? "Download" : "View Plans"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

const UseAgreementDialog = () => {
  const rootStore = useContext(StoresContext);

  const handleConfirm = () => {
    rootStore.userStore.agreeToDUA();
    const { doActionCallback } = rootStore.userStore;
    rootStore.userStore.closeYouNeedToAgreeToDUADialog();
    if (doActionCallback) {
      doActionCallback().then((err) => {
        if (err && AppToaster) {
          AppToaster.show({ message: err, intent: Intent.DANGER });
        }
      });
    }
  };

  const handleClose = () => {
    rootStore.userStore.closeYouNeedToAgreeToDUADialog();
  };

  return (
    <Dialog
      icon="info-sign"
      title="Data Use Agreement"
      isOpen={true}
      usePortal={true}
      onClose={handleClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          By downloading these data, you agree to use it for non-commercial research only. You may
          not share or publicly post the data, nor transfer or aggregate it for use in any public
          database. The Recipient acknowledges that they have read and understood and agree to the
          terms and conditions set forth in the{" "}
          <a
            href="https://www.atcc.org/Documents/Product_Use_Policy/Data_Use_Agreement.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            ATCC Data Use Agreement
          </a>
          .
        </p>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} intent={Intent.PRIMARY}>
            I agree
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DataDownloadDialogs;
