import * as React from "react";
import { NextSeo } from "next-seo";

class MaintenancePage extends React.PureComponent {
  render() {
    const logoId = "onecodex-atcc";
    const SEO = {
      noFollow: true,
      noIndex: true,
      title: "Maintenance",
    };

    return (
      <>
        <NextSeo {...SEO} />
        <div id="maintenance">
          <div id="logo-container">
            <div id={logoId} />
          </div>
          <div id="banner-container">
            <div id="banner">
              <span style={{ fontWeight: 300 }}>
                The Genome Portal is offline while we add new features.
              </span>
              <br />
              Stay tuned, we&apos;ll be back shortly.
              <p style={{ fontSize: "12pt" }}>
                In the meantime, check out our <a href="https://onecodex.com">homepage</a>,{" "}
                <a href="http://status.onecodex.com">status page</a>, or{" "}
                <a href="mailto:hello@onecodex.com">send us a note</a>.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MaintenancePage;
