import { action, computed, observable, makeObservable } from "mobx";

import { Intersection } from "../types";
import { Genome, GenomicVariant, ApiClient } from "../api";
import type AnnotationStore from "./annotation-store";

interface SearchResults {
  [key: string]: VariantRunData;
}

type VariantRunResult = {
  uuid: string;
  data: VariantRunData;
};

type VariantRunData = {
  results?: GenomicVariant[];
  referenceAssembly: {
    primaryAnnotationSet?: { uuid: string };
    genome: VariantGenome;
  };
};

export type VariantGenome = {
  name: string;
  display_name?: string;
  external_url?: string;
};

export default class ViralVariantStore {
  api: ApiClient;

  @observable runUuids: string[] = [];
  @observable selectedVariantRunUuid: string | null = null;
  @observable selectedVariant: GenomicVariant | null = null;
  @observable selectedAnnotationSetUuid: string | null = null;
  @observable assemblyLength: number | null = null;
  @observable jumpedOnTrack = false;
  @observable jumpedInTable = false;
  @observable searchResults: SearchResults = {};
  @observable includeHypotheticalProteins = true;
  @observable term = "";
  @observable intersection: Intersection | null = null;
  // For the details page
  @observable genome: null | Genome = null;
  @observable loadingGenomeDetails = false;
  @observable askForLogin = false;
  @observable error = false;
  @observable canScroll = true;

  constructor(api: ApiClient) {
    makeObservable(this);
    this.api = api;
  }

  @action
  updateFromAnnotationStore(annotationStore: AnnotationStore) {
    const genome = annotationStore.genome;
    if (genome !== null) {
      this.error = false;
      this.genome = observable(genome);
      // TODO: clean up & simplify
      if (genome.primary_assembly && genome.primary_assembly.variant_runs) {
        this.runUuids = genome.primary_assembly.variant_runs.map((v) => v.uuid);
        this.assemblyLength = genome.primary_assembly.derived_metadata.length;
        const defaultVariantRun =
          genome.primary_assembly.variant_runs.length > 0
            ? genome.primary_assembly.variant_runs[0]
            : undefined;
        const formattedVariantRuns: VariantRunResult[] = genome.primary_assembly.variant_runs.map(
          (vr) => {
            return {
              uuid: vr.uuid,
              data: {
                results: vr.results,
                referenceAssembly: {
                  genome: genome,
                  primaryAnnotationSet: vr.reference_assembly.primary_annotation_set,
                },
              },
            };
          }
        );
        this.updateSearchResults(formattedVariantRuns);
        defaultVariantRun && this.setSelectedVariantRun(defaultVariantRun.uuid);
      }
      this.loadingGenomeDetails = false;
      this.askForLogin = false;
    } else {
      this.error = true;
      this.loadingGenomeDetails = false;
      this.askForLogin = false;
      this.genome = null;
    }
  }

  @action
  setSelectedVariantRun(uuid: string) {
    this.selectedVariantRunUuid = uuid;
    this.selectedAnnotationSetUuid =
      this.selectedVariantRun.referenceAssembly.primaryAnnotationSet!.uuid;
  }

  @action
  setVariantRunUuids(uuids: string[]) {
    this.clear();
    this.runUuids = uuids;
  }

  @action
  setCanScroll(canScroll: boolean) {
    this.canScroll = canScroll;
  }

  @action
  clear() {
    this.genome = null;
    this.askForLogin = false;
    this.loadingGenomeDetails = false;
    this.canScroll = true;

    this.runUuids = [];
    this.selectedVariant = null;
    this.jumpedInTable = false;
    this.jumpedOnTrack = false;
    this.searchResults = {};
    this.includeHypotheticalProteins = true;
    this.term = "";
    this.intersection = null;
    this.error = false;
  }

  @action
  selectVariant(variant: GenomicVariant) {
    this.selectedVariant = variant;
    this.jumpedOnTrack = false;
  }

  @action
  trackJumpDone() {
    this.jumpedOnTrack = true;
  }

  @action
  tableJumpDone() {
    this.jumpedInTable = true;
  }

  @action
  toggleIncludeHypotheticalProteins() {
    this.includeHypotheticalProteins = !this.includeHypotheticalProteins;
  }

  @action
  updateSearchTerm(term: string) {
    this.term = term;
    this.searchResults = {};
  }

  @action
  updateSearchResults(data: VariantRunResult[]) {
    const updatedSearchResults: SearchResults = {};
    data.forEach((variantRun) => {
      const uuid = variantRun.uuid;
      updatedSearchResults[uuid] = variantRun.data;
    });
    this.searchResults = updatedSearchResults;
  }

  @computed
  get selectedVariantRun(): VariantRunData {
    const uuid = this.selectedVariantRunUuid!;
    return this.searchResults[uuid];
  }

  @computed
  get selectedVariantRunGeneName(): string {
    const svr = this.selectedVariantRun;
    return svr.referenceAssembly.genome.name;
  }

  @computed
  get isSearching(): boolean {
    return this.term.length >= 3;
  }

  @computed
  get variantRunUuids(): string[] {
    return this.runUuids;
  }

  @computed
  get searchTerm(): string {
    return this.isSearching ? this.term : "";
  }

  @computed
  get selectedUuid(): string | null {
    if (this.selectedVariant) {
      return this.selectedVariant.uuid;
    }
    return null;
  }

  @computed
  get needsTrackJump() {
    return this.selectedVariant && !this.jumpedOnTrack;
  }

  @computed
  get selectedVariantUuid(): string | null {
    if (this.selectedVariant) {
      return this.selectedVariant.uuid;
    }
    return null;
  }
}
