import { saveAs } from "file-saver";
import retry from "async-retry";

export async function downloadFileWithRetry(url: string, fileName: string) {
  try {
    await retry(
      async () => {
        const res = await fetch(url);

        if (res.status !== 200) {
          throw "error";
        }
        const blob = await res.blob();
        saveAs(blob, fileName);
        return;
      },
      {
        retries: 5,
      }
    );
  } catch (error) {
    throw error;
  }
}
