import React, { useContext, useEffect } from "react";
import Router from "next/router";
import getConfig from "next/config";
import { NextSeo } from "next-seo";
import {
  getEloquaCookieWithRetry,
  initializeAnalyticsTrackers,
  identifyEloquaUser,
  trackSegmentLogin,
} from "../../utils/analytics";
import qs from "qs";
import Loading from "../../components/loading";
import Cookie from "js-cookie";
import { StoresContext } from "../_app";
import { AFTER_LOGIN_ACTION_COOKIE_NAME } from "../../utils/constants";

const { publicRuntimeConfig } = getConfig();

const LoginSuccess = () => {
  const rootStore = useContext(StoresContext);

  const nextUrl = getAndResetAfterLoginUrl(rootStore.userStore.hasActiveSubscription());

  useEffect(() => {
    const state = (Cookie.get("state") || "").split(".")[0].replace("s:", "");

    if (!state || rootStore.userStore.loadCookiesConsent() !== true) {
      // Can that happen other than in some super edge cases? Ie someone having been to the signup form without
      // having been to darwin yet
      Router.push(nextUrl);
    }

    // We're initializing trackers here in order to force Eloqua's cookie to get set
    // When users reach this page, they have already opted into our ToS
    // We already bailed if the user didn't accept analytics

    const enableAnalytics = rootStore.userStore.canUseAnalytics;
    if (enableAnalytics) {
      initializeAnalyticsTrackers();
      trackSegmentLogin(rootStore.userStore.getUserId(), rootStore.userStore.getSegmentPayload());
    }

    if (enableAnalytics && publicRuntimeConfig.ELOQUA_URL && publicRuntimeConfig.ELOQUA_ID) {
      const userPayload = {
        elqFormName: "GenomePortal",
        elqSiteID: publicRuntimeConfig.ELOQUA_ID,
        emailAddress: rootStore.userStore.getUserEmail(),
        elqCustomerGUID: "",
        elqCookieWrite: 0,
      };
      getEloquaCookieWithRetry(12)
        .then((cookieString) => {
          userPayload["elqCustomerGUID"] = String(cookieString.replace("GUID=", ""));
          const userPayloadString = qs.stringify(userPayload);
          identifyEloquaUser(userPayloadString, nextUrl);
        })
        .catch((error) => {
          console.log("Could not fetch Eloqua GUID", error);
          const userPayloadString = qs.stringify(userPayload);
          identifyEloquaUser(userPayloadString, nextUrl);
        });
    } else {
      Router.push(nextUrl);
    }
  }, []);

  return (
    <>
      <NextSeo title="Login Success" noindex={true} nofollow={true} />
      <Loading text="Please wait while redirecting..." />
    </>
  );
};

function getAndResetAfterLoginUrl(hasActiveSubscription: boolean): string {
  const rawAction = Cookie.get(AFTER_LOGIN_ACTION_COOKIE_NAME);
  if (rawAction === undefined) {
    return "/";
  }

  try {
    const action = JSON.parse(rawAction) as AfterLoginAction;
    switch (action.type) {
      case "GoToHomepage":
        return "/";
      case "GoToViewPlans":
        if (hasActiveSubscription) {
          return action.prevUrl;
        }
        return "/plans";
    }
  } catch {
    return "/";
  }
}

type AfterLoginAction = {
  type: "GoToHomepage" | "GoToViewPlans";
  prevUrl: string;
};

export function setAfterLoginAction(type: AfterLoginAction["type"], prevUrl: string) {
  const action: AfterLoginAction = { type, prevUrl };
  Cookie.set(AFTER_LOGIN_ACTION_COOKIE_NAME, JSON.stringify(action));
}

export default LoginSuccess;
