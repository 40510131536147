import { ToasterInstance, Position, Toaster } from "@blueprintjs/core";

export let AppToaster: ToasterInstance | null = null;
// Toaster uses `window.document` so we cannot use it in SSR
if (typeof window !== "undefined") {
  AppToaster = Toaster.create({
    className: "recipe-toaster",
    position: Position.TOP,
  });
}
