import AnnotationStore from "./annotation-store";
import ViralVariantStore from "./viral-variant-store";
import UserStore from "./user-store";
import SequenceSearchStore from "./sequence-search-store";
import GenomeStore from "./genome-store";
import { ApiClient } from "../api";
import DiscrepancyReportsStore from "./discrepancy-reports-store";

export default class RootStore {
  public api: ApiClient;

  public annotationStore: AnnotationStore;
  public viralVariantStore: ViralVariantStore;
  public userStore: UserStore;
  public sequenceSearchStore: SequenceSearchStore;
  public genomeStore: GenomeStore;
  public discrepancyReportsStore: DiscrepancyReportsStore;

  constructor(data?: RootStore) {
    this.api = new ApiClient();

    this.annotationStore = new AnnotationStore(this.api, data?.annotationStore);
    this.viralVariantStore = new ViralVariantStore(this.api);
    this.sequenceSearchStore = new SequenceSearchStore(this.api);
    this.genomeStore = new GenomeStore(this.api, data?.genomeStore);
    this.userStore = new UserStore(this.api, data?.userStore);
    this.discrepancyReportsStore = new DiscrepancyReportsStore(
      this.api,
      data?.discrepancyReportsStore
    );

    if (data) {
      this.api.ssrCookie = data.api.ssrCookie;
    }
  }
}
