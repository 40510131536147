import * as React from "react";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { Icon, Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import { useContext } from "react";
import { StoresContext } from "../../pages/_app";

const { publicRuntimeConfig } = getConfig();

const HeaderMenu = () => {
  const router = useRouter();
  const rootStore = useContext(StoresContext);
  const userStore = rootStore.userStore;

  const logout = async () => {
    // Clear all cookies and redirect to index
    await fetch("/logout");
    window.location.href = "/";
  };

  let authItem = (
    <>
      <MenuItem href={publicRuntimeConfig.REGISTER_URL} text="Register" />
      <MenuItem href={userStore.getAuthUrl()} text="Log In" />
    </>
  );
  let profileItem = null;
  let myDiscrepancyReportsItem = null;
  if (userStore.isLoggedIn()) {
    authItem = <MenuItem text="Log Out" onClick={logout} />;
    profileItem = (
      <>
        <MenuDivider />
        <MenuItem text="Profile" href="/profile" />
      </>
    );
    if (userStore.hasActiveSubscription()) {
      myDiscrepancyReportsItem = (
        <MenuItem
          text="My Discrepancy Reports"
          onClick={() => router.push("/discrepancy-reports")}
        />
      );
    }
  }

  return (
    <Menu>
      {authItem}
      {profileItem}
      {myDiscrepancyReportsItem}
      <MenuDivider />
      <MenuItem
        text="ATCC"
        href="https://www.atcc.org/"
        target="_blank"
        rel="noopener noreferrer"
      />
      <MenuItem
        text="One Codex"
        href="https://app.onecodex.com/"
        target="_blank"
        rel="noopener noreferrer"
      />
      <MenuDivider />
      <MenuItem
        text="Share Feedback"
        href="https://www.surveymonkey.com/r/GenomePortal"
        target="_blank"
        rel="noopener noreferrer"
        labelElement={<Icon icon="document-open" className="atcc-link" />}
      />
    </Menu>
  );
};

export default HeaderMenu;
