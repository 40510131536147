import RootStore from "./root-store";
import { isServer } from "../pages/_app";

export default RootStore;

// We use a singleton store on the client only since we don't want multiple requests to share
// the data in the backend
let clientStore: RootStore | undefined;

export function initializeStore(data?: RootStore): RootStore {
  const store = clientStore ?? new RootStore(data);
  if (isServer) {
    return store;
  }

  if (!clientStore) {
    clientStore = store;
    return store;
  }
  return store;
}
