import * as Sentry from "@sentry/browser";
import e from "express";

/**
 * Send an error event to Sentry.
 *
 * Server-side:
 * Next.js captures SSR errors and never passes them to the Sentry
 * middleware. It does, however, render the _error.js component, so
 * we can manually fire Sentry events here.
 *
 * Client-side:
 * Unhandled client exceptions will always bubble up to the _error.js
 * component, so we can manually fire events here.
 */
export const notifySentry = (err: Error | unknown, req: e.Request, statusCode: number) => {
  Sentry.configureScope((scope) => {
    if (!req) {
      scope.setTag(`ssr`, "false");
    } else {
      scope.setTag(`ssr`, "true");
      scope.setExtra(`url`, req.url);
      scope.setExtra(`params`, req.params);
      scope.setExtra(`query`, req.query);
      scope.setExtra(`statusCode`, statusCode);
      scope.setExtra(`headers`, req.headers);
    }
  });

  if (err instanceof Error) {
    Sentry.captureException(err);
  } else if (typeof err === "string") {
    Sentry.captureMessage(err);
  }
};

export const notifySentryCustomMessage = (
  err: Error | unknown,
  url: string,
  statusCode: number
) => {
  Sentry.configureScope((scope) => {
    scope.setExtra(`url`, url), scope.setExtra(`statusCode`, statusCode);
  });
  if (err instanceof Error) {
    Sentry.captureException(err);
  } else if (typeof err === "string") {
    Sentry.captureMessage(err);
  }
};
