import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ApiClient, DiscrepancyReportRun, PaginationMetadata } from "../api";

export type DiscrepancyReportsPageData = {
  runs: DiscrepancyReportRun[];
  pagination: PaginationMetadata;
  isLoading: boolean;
  isError: boolean;
};

export default class DiscrepancyReportsStore {
  api: ApiClient;

  @observable private runs = observable.array<DiscrepancyReportRun>([], { deep: false });
  @observable private pagination: PaginationMetadata = { total: 0 };
  @observable private state: "need-fetching" | "fetching" | "fetched" | "error" = "need-fetching";

  constructor(api: ApiClient, ssrData?: DiscrepancyReportsStore) {
    makeObservable(this);
    this.api = api;

    if (ssrData) {
      runInAction(() => {
        this.state = ssrData.state;
        this.runs.replace([...ssrData.runs]);
        this.replacePagination(ssrData.pagination);
      });
    }
  }

  @computed get pageData(): DiscrepancyReportsPageData {
    const runs = [...this.runs];
    const pagination = this.pagination;
    switch (this.state) {
      case "need-fetching":
        this.fetchCurrentPage();
        return { runs, pagination, isLoading: true, isError: false };
      case "fetching":
        return { runs, pagination, isLoading: true, isError: false };
      case "fetched":
        return { runs, pagination, isLoading: false, isError: false };
      case "error":
        return { runs, pagination, isLoading: false, isError: true };
    }
  }

  @action
  fetchCurrentPage = async () => {
    runInAction(() => {
      this.state = "fetching";
    });

    const { error, data, headers } = await this.api.get<DiscrepancyReportRun[]>(
      "/genome-portal-api/user-discrepancy-reports",
      {},
      { page: this.pagination.page || 1 }
    );
    runInAction(() => {
      if (error === undefined) {
        this.replacePagination(JSON.parse(headers.get("X-Pagination") || "{}"));
        this.runs.replace(data);
        this.state = "fetched";
      } else {
        this.state = "error";
      }
    });
  };

  @action
  changePage = (newPage: number) => {
    if (
      this.pagination.page === newPage ||
      this.state === "need-fetching" ||
      this.state === "fetching"
    ) {
      return;
    }
    if (newPage >= 1 && newPage <= (this.pagination.total_pages || 1)) {
      this.state = "need-fetching";
      this.pagination.page = newPage;
    }
  };

  private replacePagination(newPagination: PaginationMetadata) {
    const keys = Object.keys(newPagination) as (keyof PaginationMetadata)[];
    for (const key of keys) {
      this.pagination[key] = newPagination[key];
    }
  }
}
