export default {
	title: "ATCC Genome Portal",
	titleTemplate: "%s | ATCC Genome Portal",
	description: "The ATCC Genome Portal is a repository of high-quality, authenticated microbial reference genomes.",
	openGraph: {
		type: "website",
		locale: "en_IE",
		url: "https://genomes.atcc.org",
		site_name: "ATCC Genome Portal"
	}
}