import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { useEffect } from "react";
import { AppContext, AppProps } from "next/app";
import { NextPageContext } from "next";
import getConfig from "next/config";
import Router from "next/router";
import { enableStaticRendering } from "mobx-react";
import { configure } from "mobx";
configure({ useProxies: "never" });
import { FocusStyleManager } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import MaintenancePage from "./maintenance";
import Layout from "../components/layout";
import "../styles/atcc.scss";

// https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();

import CookieConsent from "../components/cookie-consent";
import { initializeAnalyticsTrackers } from "../utils/analytics";

import { DefaultSeo } from "next-seo";
import ATCC_SEO from "../next-seo-atcc.config";

const { publicRuntimeConfig } = getConfig();
export const isProd = !!publicRuntimeConfig.PROD;
export const isServer = typeof window === "undefined";
export type DarwinPageContext = { rootStore: RootStore };

const isMaintenance = !!publicRuntimeConfig.MAINTENANCE_MODE;

if (publicRuntimeConfig.SENTRY_DSN) {
  let env;
  if (isProd) {
    env = "production-atcc";
  } else {
    env = "staging-atcc";
  }
  Sentry.init({
    ignoreErrors: [
      // bots trying to find any installed 3rd parties
      "is not defined",
      // some random network errors
      "Failed to fetch",
      "NetworkError when attempting to fetch resource",
      // Some plugins?
      "Cannot read properties of null (reading 'document')",
    ],
    dsn: publicRuntimeConfig.SENTRY_DSN,
    environment: env,
  });
}

if (isServer) {
  // https://github.com/mobxjs/mobx-react#server-side-rendering-with-usestaticrendering
  enableStaticRendering(true);
  configure({
    enforceActions: "observed",
  });
} else {
  // https://blueprintjs.com/docs/#blueprint/getting-started.dom4
  // It is included in dev but for some reasons it is missing when doing production builds
  // so we are forcing the polyfilling in all cases
  require("dom4");
}

import RootStore, { initializeStore } from "../stores";

import { createContext } from "react";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import { ApiClient } from "../api";

// The TS type requires a default value for the context but it will always be replaced
export const StoresContext = createContext<RootStore>(new RootStore());
// Same here
export const ApiContext = createContext<ApiClient>(new ApiClient());

function getSeoConfig() {
  return ATCC_SEO;
}

const DarwinApp = ({ Component, pageProps }: AppProps<DarwinPageContext>) => {
  if (isMaintenance) {
    return <MaintenancePage />;
  }

  const store = initializeStore(pageProps?.rootStore);

  useEffect(() => {
    if (!store.userStore.canUseAnalytics) {
      return;
    }

    initializeAnalyticsTrackers();

    Router.events.on("routeChangeComplete", () => {
      if (!isServer) {
        window.analytics.page();
        if (publicRuntimeConfig.ELOQUA_ID) {
          try {
            window._elq.trackEvent(window.location.href);
          } catch {
            console.log("not sending to eloqua");
          }
        }
      }
    });

    // And then Eloqua separately
    if (publicRuntimeConfig.ELOQUA_ID) {
      window._elqQ = [];
      window._elqQ.push(["elqTrackPageView"]);
      window._elqQ.push(["elqSetSiteId", 584437826]);
      window._elqQ.push(["elqUseFirstPartyCookie", "elq-tracking.genomes.atcc.org"]);
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://img04.en25.com/i/elqCfg.min.js";
      const x = document.getElementById("eloqua-div");
      x!.parentNode!.insertBefore(s, x);
      setTimeout(() => {
        try {
          window._elq.trackEvent(window.location.href);
        } catch {
          console.log("not sending to eloqua");
        }
      }, 1000);
    }
  }, []);

  return (
    <>
      <DefaultSeo {...getSeoConfig()} />
      <StoresContext.Provider value={store}>
        <ApiContext.Provider value={store.api}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
          <CookieConsent />
        </ApiContext.Provider>
      </StoresContext.Provider>
    </>
  );
};

// There is a big debate it seems between getInitialProps and getServerSideProps.
// We are using getInitialProps it works better with MobX
DarwinApp.getInitialProps = async (appContext: AppContext) => {
  let rootStore: RootStore;
  if (isServer) {
    // We are going to fill our store instead of passing down props and hydrate local stores later
    rootStore = new RootStore();
  } else {
    rootStore = initializeStore();
  }
  let pageProps = {};

  if (appContext.ctx.req) {
    const { cookies } = appContext.ctx.req as NextIncomingMessage & {
      // It is provided by cookie-parser
      cookies: { [key: string]: string };
    };
    if (isServer) {
      rootStore.api.ssrCookie = appContext.ctx.req.headers.cookie;
    }
    rootStore.userStore.loadFromHeader(cookies);
    await rootStore.userStore.reloadUserData();
  }

  if (appContext.Component.getInitialProps) {
    (appContext.ctx as NextPageContext & DarwinPageContext).rootStore = rootStore;
    pageProps = await appContext.Component.getInitialProps(appContext.ctx);
  }

  return { pageProps: { rootStore, ...pageProps } };
};

export default DarwinApp;
