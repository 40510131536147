import React, { PropsWithChildren } from "react";
import Head from "next/head";
import Script from "next/script";

import AppHeader from "./header";
import DataDownloadDialogs from "../data-download-dialogs";
import PageBanners from "../page-banners";
import { useRouter } from "next/router";

const AtccLayout = (props: PropsWithChildren) => {
  const router = useRouter();
  const homepageStyle = router.route === "/" ? "homepage" : "";

  return (
    <div id="root">
      <Script>
        {`  !function(){var analytics=window.analytics=window.analytics||[];
              if(!analytics.initialize)if(analytics.invoked)window.console&&
              console.error&&console.error("Segment snippet included twice.");
              else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick",
              "trackLink","trackForm","pageview","identify","reset","group","track",
              "ready","alias","debug","page","once","off","on"];
              analytics.factory=function(t){return function(){
              var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);
              return analytics}};for(var t=0;t<analytics.methods.length;t++)
              {var e=analytics.methods[t];analytics[e]=analytics.factory(e)}
              analytics.load=function(t,e){var n=document.createElement("script");
              n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";
              var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);
              analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
              }}();`}
      </Script>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="ahrefs-site-verification"
          content="b37c1f42fef3fc9c889fe9af9da9a9ab185f6909f197dbc1fe17e414e0a9a7f3"
        />
        <link rel="shortcut icon" href="/static/atcc/favicon.png" />
      </Head>

      <AppHeader />

      <PageBanners />

      <div className={"app-container " + homepageStyle}>{props.children}</div>

      <footer id="atcc-footer" className={homepageStyle}>
        <a href="https://www.onecodex.com/" target="_blank" rel="noopener noreferrer">
          <div className="powered-by">
            <span>Powered by</span>
            <img src="/static/onecodex-logo@2x.png" alt="One Codex" />
          </div>
        </a>

        <div className="certs">
          <a
            href="https://www.atcc.org/applications/reference-quality-data/discover-the-atcc-genome-portal"
            target="_blank"
            rel="noopener noreferrer"
            className="atcc-link"
          >
            <span>Discover the ATCC Genome Portal</span>
          </a>
          <a
            href="https://www.atcc.org/policies/product-use-policies/data-use-agreement"
            target="_blank"
            rel="noopener noreferrer"
            className="atcc-link"
          >
            <span>Data Use Agreement</span>
          </a>
          <span>
            Need Help? Contact us at{" "}
            <a
              href="https://www.atcc.org/support/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="atcc-link"
            >
              ATCC Support
            </a>
          </span>
        </div>
      </footer>
      <DataDownloadDialogs />
    </div>
  );
};

export default AtccLayout;
