import { observable } from "mobx";
import qs from "qs";
import Router from "next/router";

export interface SearchFilterProps {
  key: SearchFilterKeys;
  val: SearchFilterValue;
  title: string;
}

export type SearchFilterValue = string[] | string | boolean | null;

export const getDefaultSearchObject = () => {
  return {
    tags: observable.array<string>([]),
    isolation: null,
    type_strain: null,
    bsl: null,
    taxon_id: null,
    text: null,
    catalog_number: null,
    taxonomy: null,
  };
};

export type SearchFilterKeys = keyof ReturnType<typeof getDefaultSearchObject>;

export const SEARCH_SECTIONS: SearchFilterProps[] = [
  { title: "Name", key: "text", val: null },
  { title: "Catalog Number", key: "catalog_number", val: null },
  { title: "Tag", key: "tags", val: null },
  { title: "Taxonomy", key: "taxonomy", val: null },
  { title: "Isolation", key: "isolation", val: null },
  { title: "Type Strain", key: "type_strain", val: null },
  { title: "Biosafety Level", key: "bsl", val: null },
];
export const SEARCH_SECTIONS_KEYS = SEARCH_SECTIONS.map((section) => section.key);

export function performSearch(key: string, value: string) {
  const queryString = qs.stringify({ [key]: value });
  Router.push({ pathname: "/genomes", query: queryString });
}
